import React, { useState, useEffect, useRef } from 'react'
import toastr from 'react-hot-toast'
import InfoBanner from '../components/HPComponent/InfoBanner';
import ProductCard from '../components/ProductCard'
import { firestore as db } from '../firebase';
import image1 from '../assets/logo.png'
import image2 from '../assets/image.png'
import '../style/homepage.css'
import _ from 'lodash';
import moment from 'moment'
import { AnimatePresence, motion, useAnimation } from "framer-motion";

function Homepage({ history }) {
    const [blogs, setBlogs] = useState([]);
    const originOffset = useRef({ top: 0, left: 0 });
    const controls = useAnimation();

    useEffect(() => {
        db.collection('blog').get().then(querySnapshot => {
            const documents = querySnapshot.docs.map(doc => doc.data())
            console.log(documents)
            setBlogs(documents)
            controls.start("visible")
        }).catch((error) => {
            console.error("Error writing document: ", error);
            toastr.error("Something went wrong. Please try again")
        });
    }, []);
    
    return (
        <div className="container-fluid p-2" style={{ minHeight: '86vh' }}>
            <div className="container-xl mb-md-2 pb-md-2 mb-5 pb-5 py-2 px-0">
                <div className="fs-4 text-primary fw-bolder">
                    Featured Blogs
                </div>
                <div className="row g-md-2 justify-content-center">
                    {blogs.map(blog => {
                        const data = JSON.parse(blog.data);
                        return <motion.div
                            animate={controls} variants={{}}
                            className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3">
                            <BlogCard
                                onClick={() => history.push(`/blog/read/${blog.id}`, data)}
                                title={data?.blocks[0]?.data?.text}
                                image={_.find(data?.blocks, { type: 'image' })?.data?.url || image2}
                                content={_.find(data?.blocks, { type: 'paragraph' })?.data?.text || "No content"}
                                id={'sample-blog'}
                                postedOn={data?.time}
                            />
                        </motion.div>
                    })}
                    {/* <BlogCard
                        title={'Top 10 cat for car asd akjsndjkns ashkdfbjd ajsdbj'}
                        image={image2}
                        id={'sample-blog'}
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default Homepage



const BlogCard = ({ title, image, id, onClick, content, postedOn }) => {
    const transition = { duration: 0.6, ease: "easeInOut", delayChildren: 2 };

    const postVariants = {
        initial: { y: -1000, opacity: 0 },
        enter: { y: 10, opacity: 1, transition },
        exit: { y: 40, opacity: 0, transition }
    };
    return (
        <motion.div
            initial="exit"
            drag
            animate="enter"
            dragElastic={true}
            dragConstraints={{
                top: -1,
                left: -1,
                right: 1,
                bottom: 1,
            }}
            whileHover={{ translateY:-5 }}
            whileTap={{ scale: 0.9 }}
            exit="exit"
            variants={postVariants}
            className="position-relative bcard d-inline-block ms-1 cp"
        onClick={onClick}
        >
            <div className="bcard-img p-2 overflow-hidden" style={{ overflow: 'hidden' }}>
                <img src={image} alt={title} />
            </div>
            <div className="bcard-body px-2">
                <div className="bcard-title">
                    {title}
                </div>
                <div className="bcard-content">
                    {content}
                </div>
                <hr className="my-1 bg-danger" />
                <div className="text-info">{moment(postedOn).fromNow()}</div>
            </div>
        </motion.div>)
}