import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";

function EzSelect({
  setSelectedItem,
  selectedItem,
  placeholder,
  options = [],
  error, id, label, required, showLabel
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  return (
    <div className="my-4">
      {
        showLabel && 
        <div>
          <div className="d-inline-block pg-text__bold text-primary">{label}</div> {required && <span className="textc-primary fst-italic">(Required)</span>}
        </div>
      }
      <div className="cursor-pointer">
        <Dropdown
          className="d-inline-block position-relative w-100"
          onToggle={() => setIsPopupOpen(!isPopupOpen)}
          id={id}
        >
          <Dropdown.Toggle
            as="div"
            bsPrefix="border-radius py-auto pg-text d-flex justify-content-between"
            style={{
              background:'var(--input-color)',
              // border: error ? "2px solid #f00" : "3px solid var(--text-color)",
              borderRadius: "12px",
              height: 50,
              padding: "6px 10px",
              width: "100%",
            }}
          >
            <span
              id="selected-item"
              className="my-auto"
              style={{ color: selectedItem ? "var(--text-color)" : "grey" }}
            >
              {selectedItem || placeholder}
            </span>{" "}
            <i
              className="fa fa-chevron-down my-auto transition-all fs-5 fw-bold text-primary"
              aria-hidden="true"
              style={{
                transform: isPopupOpen ? "rotate(-180deg)" : "rotate(0deg)",
              }}
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            bsPrefix="dropdown-menu w-100 border-radius"
            style={{
              borderRadius: 8,
              background: "var(--bg-color)",
              color:'var(--text-color)',
              zIndex: 9999,
              opacity: 1,
              maxHeight: "300px",
              overflowY: "auto"
            }}
          >
            {options.map((option, i) => {
              return (
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => setSelectedItem(option)}
                  key={i}
                  className="pg-text text-primary fw-bold"
                  id={`select-option__${option}`}
                >
                  {option}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
  //   </div>;
}

export default EzSelect;
