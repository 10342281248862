import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import AnyButton from 'editorjs-button';

import { storage } from '../firebase'

export const EDITOR_JS_TOOLS = (image = Image) => ({
    image,
    embed: Embed,
    table: Table,
    marker: Marker,
    list: List,
    warning: Warning,
    AnyButton: {
        class: AnyButton,
        inlineToolbar: false,
        config: {
            css: {
                "btnColor": "text-primary",
            }
        }
    },
    code: Code,
    linkTool: {
        class: LinkTool,
        config: {
            endpoint: '', // Your backend endpoint for url data fetching
        }
    },
    raw: Raw,
    header: Header,
    quote: Quote,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    // image: SimpleImage
})