import React, { useState, useEffect } from 'react'
import './App.css';
import Mainlayout from './layout/Mainlayout';
import UserProvider from './Provider/UserProvider';
import  { Toaster } from 'react-hot-toast';

function App() {
  useEffect(() => {

    if (localStorage.getItem('theme') === "null")
      localStorage.setItem('theme', 'dark')
    else document.body.classList.add(localStorage.getItem('theme'))
  }, [])
  return (
    <div className="position-relative">
      <Toaster 
         position="bottom-left"
      />
      {/* <div className="round-animator" /> */}
      <div className="bg-primary app">
        <UserProvider>
          <Mainlayout />
        </UserProvider>
      </div>
    </div>
  );
}

export default App;
