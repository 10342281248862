import React, { useState, useEffect } from 'react'
import EzInput from './EzInput';
import toastr from 'react-hot-toast'
function EzTagInput({ prefixIcon, postfixIcon, tags, setTags, removeTag, label, showLabel, ...props }) {
    const style = {
        label: {
            border: 0,
            background: 'var(--input-color)',
            // maxWidth: 500,
            width: '100%',
            borderRadius: 12,
            padding: 5
        },
        input: {
            height: 40,
            background: 'transparent',
            width: '100%',
            outline: "none",
            border: 'none'
        },
        icon: {
            width: '10%'
        }
    }

    const [value, setValue] = useState("")
    return (
        <div>
            {showLabel && <div className="text-primary">
                {label}
            </div>}
            <EzInput
                onKeyPress={(e) => {
                    var keyCode = e.code || e.key;
                    if (keyCode === "Enter") {
                        if (tags.length > 5) toastr.error('Only five tags allowed');
                        else if (tags.includes(e.target.value))
                        toastr.error('This tag is there');
                        else {
                            setTags([...tags, e.target.value])
                            setValue("")
                        }
                    }
                }}
                {...props}
                value={value}
                type="text" onInput={(e) => setValue(e.target.value)} />
            <div>
                {tags.map((tag, i) => (
                    <span
                        key={i}
                        className="cp badge me-1 my-2 bg-text text-bg"
                        onClick={() => {
                            setTags(tags => {
                                let dTags = [...tags]
                                dTags.splice(i, 1);
                                return dTags
                            })
                        }}>
                        {tag}
                    </span>))}
            </div>
        </div>
    )
}

export default EzTagInput
