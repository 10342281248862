import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import firebase, { firestore as db, storage } from '../firebase';
import { UserContext } from '../Provider/UserProvider';
import toastr from 'react-hot-toast'

import ReactEditorJs from "react-editor-js";
import Image from "@editorjs/image";

import EzInput from '../components/EzInput';
import EzTagInput from '../components/EzTagInput';
import EzSelect from '../components/EzSelect';
import { EDITOR_JS_TOOLS } from './EditorJSConstants'
import '../style/editorjs.css'


function BlogPage({ blogDataProp, editable = true }) {
    const user = useContext(UserContext);
    const [editorInstance, setEditorInstance] = useState(null)

    const [editBlog, seteditBlog] = useState(!editable);
    const [blogType, setBlogType] = useState("")
    const [tags, setTags] = useState([]);
    const [blogData, setBlogData] = useState(blogDataProp || { time: 1556098174501, blocks: [{ type: "header", data: { text: "Blog Title ", level: 1 } },] });

    useEffect(() => {
        console.log("blogdata--", blogData);
        console.log(_.find(blogData?.blocks, { type: 'image' }))
    }, [blogData]);

    const image = {
        class: Image,
        config: {
            uploader: {
                async uploadByFile(file) {
                    var storageRef = storage.ref();
                    var imagesRef = storageRef.child('EditorJS').child(`${user?.uid}/` + file.name);
                    var metadata = {
                        contentType: 'image/jpeg'
                    };
                    var uploadTask = await imagesRef.put(file, metadata);
                    console.log("Uploaded successfully!", uploadTask);
                    const downloadURL = await uploadTask.ref.getDownloadURL();
                    console.log(downloadURL);
                    return {
                        success: 1,
                        file: { url: downloadURL }
                    }
                }
            }
        }
    };

    const onSave = async () => {
        // console.log(blogData);
        const blogTitle = _.kebabCase(blogData?.blocks[0]?.data?.text);

        console.log("btit", blogTitle, blogData, blogType, user.uid)
        if (!blogType || tags.length < 1)
            return toastr.error("Please select blog type and tags")
        if (blogData.blocks.length < 5)
            return toastr.error("A blog should have atleast 5 lines or para");

        let document = await db.collection("blog").doc(blogTitle).get();
        if (document && document.exists) {
            toastr.error("Blog with same title already exist")
        } else {
            db.collection('blog').doc(blogTitle).set({
                data: JSON.stringify(blogData),
                id: blogTitle,
                type: blogType,
                tags: tags,
                blogger: user?.uid
            }).then(() => {
                console.log("Document successfully written!");
                toastr.success("Blog posted successfully");
            })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                    toastr.error("Something went wrong. Please try again")
                });
        }
    }

    const clearBlog = () => {
        // if (editorInstance?.blocks)
        editorInstance?.blocks?.render({ time: 1556098174501, blocks: [{ type: "header", data: { text: "Blog Title New", level: 1 } },] })
        setBlogData({ time: 1556098174501, blocks: [{ type: "header", data: { text: "Blog Title New", level: 1 } },] });
    }

    useEffect(() => {
        // console.log(document.getElementsByClassName('codex-editor')[0].style)
        // if (!editBlog)
    }, [editBlog])

    return (
        <div className="container py-2 py-md-4" style={{}} onKeyDown={(e) => {
            if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                toastr.error("Click save button to post the blog")
            }
        }}>
            <h2 className="text-secondary fw-bold">Write your own blog</h2>

            <div className="" style={{ width: window.innerWidth < 600 ? '100%' : '60%' }}>
                <EzSelect
                    label="Select blog type:"
                    showLabel={true}
                    placeholder="Eg: Electronic"
                    setSelectedItem={setBlogType}
                    selectedItem={blogType}
                    options={['Electronic', 'Education', 'Other']}
                />
                <EzTagInput
                    label="Enter related tags"
                    showLabel={true}
                    tags={tags}
                    setTags={setTags}
                    placeholder="Enter tags" />
            </div>
            <div className="blog-editor" id="blog-editor">
                <div className="text-primary mt-2">
                    Write your blog here
                </div>
                <ReactEditorJs
                    logLevel="ERROR"
                    holder="blog-editor"
                    instanceRef={instance => setEditorInstance(instance)}
                    autofocus={true}
                    // enableReInitialize={true}
                    tools={EDITOR_JS_TOOLS(image)}
                    onChange={(a, updatedData) => setBlogData(updatedData)}
                    readOnly={editBlog}
                    data={blogData}

                />
            </div>
            <div className="d-flex justify-content-between">
                <button className="btn btn-ouline mx-2" onClick={clearBlog}>Clear all</button>
                <div>
                    <button className="btn btn-secondary mx-2" onClick={() => seteditBlog(!editBlog)}>{!editBlog ? "Preview" : "Edit"}</button>
                    <button className="btn btn-primary" onClick={() => onSave()}>Save </button>
                </div>
            </div>

        </div>
    )
}

export default BlogPage
