import React, { useState, useEffect } from 'react'
import BlogRenderer from '../components/BlogRenderer';
import { firestore as db } from '../firebase';
import _ from "lodash";
import BlogPage from './BlogPage'

function ReadBlog({ blogData, history, location }) {
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // if (!location.state)
        if (location.pathname.split('/').length === 4) {
            const blogId = location.pathname.split('/')[3];
            db.collection('blog').doc(blogId).get().then(querySnapshot => {
                const documents = querySnapshot.data()
                setBlog({ ...documents });
                setLoading(false)
            }).catch((error) => {
                console.error("Error writing document: ", error);
                // toastr.error("Something went wrong. Please try again")
            });
        } else
            setLoading(false)
    }, [])

    useEffect(() => {
        console.log("actual data:", blog)

    }, [blog])

    return (
        <div className="p-2">
            {loading
                ? <div className="text-center text-primary">Loading...</div>
                : <div>
                    <div className="d-flex justify-content-end text-primary fs-4">
                        Share your blog
                        <i class="mx-2 cp fas fa-share-alt my-auto"
                            onClick={async () => {
                                await navigator.share({
                                    title: 'EZCommify',
                                    text: `Hey, I have wrote my blog on ezcommify on topic ${_.startCase(blog.id)} \n Check below link`,
                                    url: window.location.href,
                                })
                            }}
                        ></i></div>
                    <BlogRenderer data={location.state || JSON.parse(blog.data)} editable={false} />
                </div>}
        </div>
    )
}

export default ReadBlog
