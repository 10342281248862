import React, { useState, useEffect } from 'react'
import { EDITOR_JS_TOOLS } from '../screens/EditorJSConstants';
import EditorJS from 'react-editor-js';

function BlogRenderer({ data }) {
    console.log("recieving data", data)
    return (
        <div className="container blog-renderer" id="blog-renderer">
            <EditorJS
                holder="blog-renderer"
                tools={EDITOR_JS_TOOLS()}
                data={data}
                readOnly={true}
            />
        </div>
    )
}

export default BlogRenderer
